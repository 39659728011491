































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IFile } from '@store/modules/project-documents/Interfaces';

import './scss/PopupDocumentsList.scss';
import { IProject } from '@store/modules/project/Interfaces';
import { ProjectActions, ProjectMutations } from '@store/modules/project/Types';

import { namespace } from 'vuex-class';
import { Certificate, createDetachedSignature, createHash, getUserCertificates, isValidSystemSetup } from "crypto-pro";
import http from '@/http';
import { ProjectDocumentsActions } from "@store/modules/project-documents/Types";
import { IUserState } from "@store/modules/user/Interfaces";

const NSDocuments = namespace('storeProjectDocuments');
const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject')

@Component({
    name: 'PopupDocumentsList',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseLink: () => import('@components/BaseLink/BaseLink.vue'),
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
        BaseCheckbox: () => import('@components/BaseCheckbox/BaseCheckbox.vue'),
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
    }
})

export default class PopupDocumentsList extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
    }

    @NSProject.Getter('projectId') projectId!: number;
    @NSProject.Getter('projectData') projectData!: IProject;
    @NSProject.Action(ProjectActions.A_READ_OBJECTS) readObjects!: (payload) => Promise<void>
    @NSProject.Mutation(ProjectMutations.M_SET_NEW_DOCUMENTS) setNewDocuments!: (payload) => Promise<void>
    @NSUser.Getter('userData') userData!: IUserState;
    @NSDocuments.Action(ProjectDocumentsActions.A_SIGN_DOCUMENT) signDocument!: (data) => Promise<void>

    documentsList: IFile[] = [];
    documentData: any = null;
    selectedFiles: IFile[] = [];
    signLoading: boolean = false;

    certs: Certificate[] = [];
    pluginLoading: boolean = false;
    noCertLoad = false;
    certThumbprint: Certificate | null = null;

    get certsFormatted() {
        return this.certs.map(item => {
            return {
                label: item.name,
                ...item,
            }
        });
    }

    get placeholderForCertificateSelect() {
        return this.certThumbprint ? this.certThumbprint.name : 'Выбор сертификата для подписания';
    }

    get documentTitle(): string {
        return this.documentData?.title ? this.documentData.title : '-';
    }

    get documentDescription(): string {
        return this.documentData?.description ? this.documentData.description : '';
    }

    get btnDisabled() {
        return !this.selectedFiles?.length || !this.certThumbprint || this.signLoading;
    }

    openPopup() {
        this.selectedFiles = [];
        if (this.$refs.basePopup) {
            this.$refs.basePopup.openPopup();
            setTimeout(() => {
                if (!this.certs.length) {
                    this.loginPlugin();
                }
            }, 1)
        }
    }

    closePopup() {
        this.$refs.basePopup.closePopup();
        this.onClose();
    }

    checkNewDocs() {
        if (this.projectData.newDocuments && this.projectData.newDocuments.includes(this.documentData.uuid)) {
            this.readObjects({
                appId: this.projectId,
                ids: [this.documentData.uuid],
                type: 'document',
            }).then(() => {
                if (this.projectData && this.projectData.newDocuments) {
                    const newDocsIds = this.projectData.newDocuments.filter(taskId => taskId !== this.documentData.uuid);
                    this.setNewDocuments(newDocsIds);
                }
            })
        }
    }

    onClose() {
        this.documentsList = [];
        this.documentData = null;
    }

    setDocuments(data) {
        this.documentsList = data.documents;
        this.documentData = data.docData;
        this.checkNewDocs();
    }

    thereAreSigned(item) {
        return item.signatures.length > 0;
    }

    onFileClick(fileId) {
        this.$emit('signature', { docData: this.documentData, fileId: fileId });
    }

    fileIsSelected(file) {
        return this.selectedFiles.indexOf(file) !== -1;
    }

    onSelectFile(file) {
        const fileIndex = this.selectedFiles.indexOf(file);
        if (fileIndex === -1) {
            this.selectedFiles.push(file);
        } else {
            this.selectedFiles.splice(fileIndex, 1);
        }
    }

    onSuccess() {
        if (!this.selectedFiles?.length) {
            return null;
        }
        this.startSign();
    }

    async startSign() {
        this.signLoading = true
        try {
            for (const item of this.selectedFiles) {
                await this.signFile(item);
            }
        } catch (error) {
            console.error(error);
        } finally {
            this.signLoading = false;
        }
    }

    readFile(item) {
        return new Promise((resolve) => {
            const fileReader = new FileReader();
            fileReader.onload = function () {
                resolve(this.result);
            };
            http.get(item.linkView, {responseType: 'blob'})
                .then((res) => {
                    fileReader.readAsArrayBuffer(res.data)
                })
        });
    }

    async signFile(item) {
        const messagePromise = this.readFile(item);
        messagePromise.then((message) => {
            createHash(message as ArrayBuffer).then((hash) => {
                if (this.certThumbprint) {
                    const signaturePromise = createDetachedSignature(this.certThumbprint.thumbprint, hash);
                    signaturePromise.then((signature) => {
                        if (item && item.uuid) {
                            this.signDocument({
                                sign: signature,
                                appId: this.projectId,
                                userId: this.userData.userInfo.id,
                                fileId: item.uuid
                            }).then(() => {
                                this.afterSign();
                            })
                        }
                    })
                }
            });
        }, function (fileError) {
            console.error(fileError);
        })
    }

    async loginPlugin() {
        this.pluginLoading = true;
        try {
            if (!await isValidSystemSetup()) {
                throw new Error('no valid settings');
            }
            this.certs = await getUserCertificates();
        } catch (e: any) {
            this.noCertLoad = true;
            console.error(e.message);
        } finally {
            this.pluginLoading = false;
        }
    }

    afterSign() {
        this.closePopup();
        this.$emit('signed');
    }
}
